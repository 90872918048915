/* Basic styling */
body {
  font-family: 'Calibri', serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

.resume-container {
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

header {
  text-align: center;
  margin-bottom: 20px;
}

header h1 {
  margin: 0;
  font-size: 2.5rem;
  color: black;
}

header p {
  margin: 5px 0;
  font-size: 1.1rem;
}

main {
  padding: 10px;
}

section {
  margin-bottom: 10px;
}

section h2 {
  color: black;
  font-size: 1.5rem;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

ul li {
  margin-bottom: 5px;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.extra-info {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  background-color: #f0f0f0;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.extra-info p {
  margin-bottom: 10px;
}

#visitor-count {
  border: 2px solid #333;
  padding: 10px;
  text-align: center;
  margin-top: 20px;
  background-color: #f0f0f0;
  color: #333; 
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 8px; 
}

#education .education {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
}

#education .left {
  text-align: left;
  flex-grow: 1; 
}

#education .right {
  text-align: right;
  flex-shrink: 0; 
}


#experience .job-info {
  margin-bottom: 0px;
}

#experience .job-info strong {
  display: inline-block;
  margin-right: 0px;
}

#experience .job-info .right {
  float: right;
}


#projects p,
#certifications p {
  margin-bottom: 0px;
}

#projects a,
#certifications a {
  float: right;
}

* {
  margin: 0px;
  padding: 0px
}

/*extra info*/
/* Style for the close button */
.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  outline: none;
}